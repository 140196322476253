import { Col, Form, Input, InputNumber, Radio, Row, Select } from "antd";
import chunkArray from "lib/chunkArray";
import {
  ICityCollectionFields,
  IHouseTypeCollectionFields,
  ISpaceStyleCollectionFields,
  StrapiPagination,
} from "types/api";

const { Option } = Select;

interface IShowcaseFormFields {
  houseTypes: StrapiPagination<IHouseTypeCollectionFields>;
  spaceStyles: StrapiPagination<ISpaceStyleCollectionFields>;
  cities: StrapiPagination<ICityCollectionFields>;
}

const ShowcaseFormFields = (props: IShowcaseFormFields) => {
  const { houseTypes, spaceStyles, cities } = props;
  const houseTypesChunks = chunkArray(houseTypes.data, 8);
  const spaceStylesChunks = chunkArray(spaceStyles.data, 8);

  return (
    <div>
      <Row gutter={20}>
        <Col span={8}>
          <Form.Item
            label="案例名稱"
            name="title"
            rules={[{ required: true, message: "請輸入案例名稱" }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="案例預算(最低 50 萬)"
            name="budget"
            rules={[{ required: true, message: "請輸入案例預算" }]}
          >
            <InputNumber addonAfter="萬(新台幣)" min={50} controls={false} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="案例坪數"
            name="size"
            rules={[{ required: true, message: "請輸入案例坪數" }]}
          >
            <InputNumber addonAfter="坪" min={1} controls={false} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="案例地區"
            name="location"
            rules={[{ required: true, message: "請輸入案例地區" }]}
          >
            <Select placeholder="選擇地區">
              {cities.data.map((c) => (
                <Option key={c.attributes.name} value={c.id}>
                  {c.attributes.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col span={24}>
          <Form.Item
            label="案例說明"
            name="description"
            rules={[{ required: true, message: "請輸入案例說明" }]}
          >
            <Input.TextArea rows={5} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20} style={{ marginTop: "30px" }}>
        <Col span={24}>
          <Form.Item
            label="案件類型"
            name="houseType"
            rules={[
              {
                required: true,
                message: "最少勾選一個案件類型",
              },
            ]}
          >
            <Radio.Group style={{ display: "block" }}>
              {houseTypesChunks.map((chunk, index) => (
                <Row key={index} className="w-full">
                  {chunk.map((houseType) => {
                    return (
                      <Col
                        key={houseType.attributes.name}
                        span={3}
                        style={{ marginTop: "15px" }}
                      >
                        <Radio value={houseType.id}>
                          {houseType.attributes.name}
                        </Radio>
                      </Col>
                    );
                  })}
                </Row>
              ))}
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20} style={{ marginTop: "30px" }}>
        <Col span={24}>
          <Form.Item
            label="案件風格"
            name="spaceStyle"
            rules={[
              {
                required: true,
                message: "最少勾選一個案件風格",
              },
            ]}
          >
            <Radio.Group style={{ display: "block" }}>
              {spaceStylesChunks.map((chunk, index) => (
                <Row key={index} className="w-full">
                  {chunk.map((spaceStyle) => {
                    return (
                      <Col
                        key={spaceStyle.attributes.name}
                        span={3}
                        style={{ marginTop: "15px" }}
                      >
                        <Radio value={spaceStyle.id}>
                          {spaceStyle.attributes.name}
                        </Radio>
                      </Col>
                    );
                  })}
                </Row>
              ))}
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default ShowcaseFormFields;
