import { Button, Descriptions, Space } from "antd";
import PLAN_MAPPING from "constant/planMapping";
import dateFormatter from "lib/dateFormatter";
import { useNavigate, useRouteLoaderData } from "react-router-dom";
import { IAdminLayoutLoaderData } from "routes/AdminLayout";

const UserProfile = () => {
  const { subscription, company, user } = useRouteLoaderData(
    "AdminLayout"
  ) as IAdminLayoutLoaderData;
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };
  return (
    <div>
      <Descriptions title="用戶資料">
        <Descriptions.Item label="用戶名稱">{user.username}</Descriptions.Item>
        <Descriptions.Item label="公司名稱">
          {company.data.attributes.name}
        </Descriptions.Item>
        <Descriptions.Item label="公司聯絡電話">
          {company.data.attributes.phone}
        </Descriptions.Item>
        <Descriptions.Item label="方案">
          {PLAN_MAPPING[subscription.data.attributes.plan]}
        </Descriptions.Item>
        <Descriptions.Item label="方案到期日">
          {subscription.data.attributes.dueDate
            ? dateFormatter(subscription.data.attributes.dueDate)
            : "-"}
        </Descriptions.Item>
      </Descriptions>
      <div className="text-right mt-[50px]">
        <Space>
          <Button onClick={() => navigate("/")}>返回</Button>
          <Button danger onClick={handleLogout}>
            登出
          </Button>
        </Space>
      </div>
    </div>
  );
};

export default UserProfile;
