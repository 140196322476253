import { LoaderFunctionArgs, redirect } from "react-router-dom";
import { AUTH_PROVIDERS } from "constant";
import { strapiFetch } from "lib/api";
import { AuthProvider, IUserCollectionFields } from "types/api";

const AuthRedirectLoader = async ({ params, request }: LoaderFunctionArgs) => {
  const { provider } = params;
  const url = new URL(request.url);
  const access_token = url.searchParams.get("access_token");
  if (!provider) {
    throw new Response("Must have provider", { status: 500 });
  }

  if (!AUTH_PROVIDERS.includes(provider as AuthProvider)) {
    throw new Response("Provider is not in [google, facebook]", {
      status: 500,
    });
  }
  const callbackData = await strapiFetch<{
    user: IUserCollectionFields;
    jwt: string;
  }>(`/auth/${provider}/callback`, {
    access_token,
  });
  localStorage.setItem("token", callbackData.jwt);
  throw redirect("/");
};

export { AuthRedirectLoader };
