import { strapiFetch } from "lib/api";

interface IUpdateDesignCompanyPhoto {
  companyId: string;
  photoId: string;
  data: { order?: number };
}

const updateDesignCompanyPhoto = async (props: IUpdateDesignCompanyPhoto) => {
  const { companyId, data, photoId } = props;
  return await strapiFetch(
    `/design-companies/${companyId}/design-company-photos/${photoId}`,
    {},
    {
      method: "PUT",
      data: { data },
    }
  );
};

export default updateDesignCompanyPhoto;
