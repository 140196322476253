import { strapiFetch } from "lib/api";

interface IDeleteShowcasePhoto {
  showcaseId: string;
  photoId: string;
}

const deleteShowcasePhoto = async (props: IDeleteShowcasePhoto) => {
  const { showcaseId, photoId } = props;

  return await strapiFetch(
    `/showcases/${showcaseId}/space-photos/${photoId}`,
    {},
    {
      method: "DELETE",
    }
  );
};

export default deleteShowcasePhoto;
