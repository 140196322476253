import { strapiFetch } from "lib/api";
import qs from "qs";
import Table, { ColumnsType, TableProps } from "antd/es/table";
import {
  LoaderFunctionArgs,
  useLoaderData,
  useNavigate,
} from "react-router-dom";
import {
  IConsultingLogCollectionFields,
  StrapiPagination,
  StrapiParams,
} from "types/api";
import dateFormatter from "lib/dateFormatter";

type ConsultingLogColumn = IConsultingLogCollectionFields & {
  id: number;
  key: number;
};

interface IConsultingLogsLoaderData {
  search: StrapiParams;
  consultingLogs: StrapiPagination<IConsultingLogCollectionFields>;
}

const columns: ColumnsType<ConsultingLogColumn> = [
  {
    title: "諮詢人",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "電話",
    dataIndex: "phone",
    key: "phone",
  },
  {
    title: "案件地址",
    dataIndex: "address",
    key: "address",
    render: (text) => (text ? <div>{text}</div> : "-"),
  },
  {
    title: "預算",
    key: "budget",
    dataIndex: "budget",
    render: (text) => (text ? <div>{text}萬</div> : "-"),
  },
  {
    title: "坪數",
    key: "size",
    dataIndex: "size",
    render: (text) => (text ? <div>{text}坪</div> : "-"),
  },
  {
    title: "發送時間",
    key: "createdAt",
    dataIndex: "createdAt",
    render: (text) => <div>{dateFormatter(text)}</div>,
  },
  {
    title: "備註",
    key: "note",
    dataIndex: "note",
  },
];

const ConsultingLogs = () => {
  const { consultingLogs, search } =
    useLoaderData() as IConsultingLogsLoaderData;
  const navigate = useNavigate();

  const tableDataSource = consultingLogs.data.map((d) => ({
    ...d.attributes,
    id: d.id,
    key: d.id,
  }));

  const handleTableChange: TableProps<ConsultingLogColumn>["onChange"] = (
    ...params
  ) => {
    const [pagination] = params;

    const newSearch = {
      pagination: {
        page: pagination.current,
        pageSize: pagination.pageSize,
      },
    };
    navigate(
      `/consulting-logs?${qs.stringify(newSearch, { encodeValuesOnly: true })}`
    );
  };
  return (
    <>
      <div className="flex justify-between items-center mb-[50px]">
        <h1 className="text-2xl">諮詢紀錄</h1>
      </div>
      <Table
        columns={columns}
        dataSource={tableDataSource}
        pagination={{
          pageSize: Number(search.pagination?.pageSize) || 10,
          current: Number(search.pagination?.page) || 1,
          total: consultingLogs.meta?.pagination.total,
        }}
        onChange={handleTableChange}
      />
    </>
  );
};

export const ConsultingLogsLoader = async ({
  params,
  request,
}: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const search = qs.parse(url.search, { ignoreQueryPrefix: true });
  const consultingLogs = await strapiFetch(
    "/custom-consulting-logs/my-company",
    {
      pagination: search.pagination,
      sort: ["createdAt:desc"],
    }
  );

  return {
    consultingLogs,
    search,
  };
};

export default ConsultingLogs;
