import React, { useState } from "react";
import {
  AppstoreAddOutlined,
  HomeOutlined,
  MailOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import { Avatar, Layout, Menu } from "antd";
import {
  Link,
  Outlet,
  redirect,
  useLoaderData,
  useLocation,
} from "react-router-dom";
import { strapiFetch } from "lib/api";
import {
  ICityCollectionFields,
  IHouseTypeCollectionFields,
  ISpaceAreaCollectionFields,
  ISpaceStyleCollectionFields,
  ISubscriptionCollection,
  IUserCollectionFields,
} from "types/api";
import { StrapiPagination } from "types/api/shared";
import {
  IDesignCompanyCollection,
  IDesignCompanyCollectionFields,
} from "types/api/designCompany";
import localStorage from "lib/localStorage";
import PLAN_MAPPING from "constant/planMapping";

const { Header, Sider, Content } = Layout;

const menuItems = [
  {
    key: "/",
    icon: <HomeOutlined />,
    label: <Link to="/">公司資訊</Link>,
  },
  {
    key: "/showcases",
    icon: <AppstoreAddOutlined />,
    label: <Link to="/showcases">公司案例</Link>,
  },
  {
    key: "/consulting-logs",
    icon: <MailOutlined />,
    label: <Link to="/consulting-logs">諮詢紀錄</Link>,
  },
];
export interface IAdminLayoutLoaderData {
  user: IUserCollectionFields;
  company: IDesignCompanyCollection;
  cities: StrapiPagination<ICityCollectionFields>;
  houseTypes: StrapiPagination<IHouseTypeCollectionFields>;
  spaceStyles: StrapiPagination<ISpaceStyleCollectionFields>;
  spaceAreas: StrapiPagination<ISpaceAreaCollectionFields>;
  subscription: ISubscriptionCollection;
}

const AdminLayout = () => {
  const { user, subscription } = useLoaderData() as IAdminLayoutLoaderData;
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();
  return (
    <Layout hasSider>
      <Sider trigger={null} collapsible collapsed={collapsed} theme="light">
        <Link to="/">
          <div className="p-[16px] text-primary text-center text-lg">
            設計美宅 PRO
          </div>
        </Link>
        <Menu
          theme="light"
          mode="inline"
          defaultSelectedKeys={["1"]}
          items={menuItems}
          selectedKeys={menuItems
            .map((i) => i.key)
            .filter((k) => k === location.pathname)}
        />
      </Sider>
      <Layout>
        <Header
          style={{
            paddingRight: "48px",
            paddingLeft: "24px",
            background: "#fff",
          }}
        >
          <div className="flex justify-between items-center">
            {React.createElement(
              collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
              {
                className:
                  "py-[0px] color-[#1890ff] leading-[64px] cursor-pointer transition",
                onClick: () => setCollapsed(!collapsed),
              }
            )}
            <Link to="/user/profile">
              <div className="flex items-center cursor-pointer">
                <Avatar src={user.userProfile?.avatar?.url}>
                  {user?.username[0]?.toLocaleUpperCase()}
                </Avatar>
                <div className="ml-[15px] text-black">
                  {user.username}
                  {` (${PLAN_MAPPING[subscription.data.attributes.plan]})`}
                </div>
              </div>
            </Link>
          </div>
        </Header>
        <Content
          style={{
            padding: 24,
            height: "calc(100vh - 64px)",
            overflow: "scroll",
          }}
        >
          <div className="bg-white p-[24px] max-w-[1200px] mx-auto">
            <Outlet />
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

const AdminLayoutLoader = async () => {
  let userData = null;
  try {
    userData = await strapiFetch<IUserCollectionFields>("/users/me", {
      populate: {
        role: "*",
        userProfile: {
          populate: {
            avatar: "*",
          },
        },
      },
    });
  } catch (e) {
    localStorage.clearAuth();
    throw redirect("/login");
  }
  if (!userData) throw redirect("/login");

  if (userData.role.type !== "pro") throw redirect("/ask-for-pro");

  const companiesRes = await strapiFetch<
    StrapiPagination<IDesignCompanyCollectionFields>
  >("/design-companies", {
    filters: {
      user: userData.id,
    },
    populate: {
      avatar: "*",
      acceptableHouseTypes: "*",
      acceptableCities: "*",
      goodAtStyles: "*",
      city: "*",
      photos: {
        populate: ["image"],
      },
    },
  });

  if (!companiesRes.meta?.pagination.total) {
    throw redirect("/onboarding");
  }

  if (companiesRes.meta?.pagination.total > 1) {
    // 測試帳號如果有多個 companies，不給進入
    throw new Response("衝突資料", { status: 408 });
  }

  const [cities, houseTypes, spaceStyles, spaceAreas, subscription] =
    await Promise.all([
      strapiFetch("/cities", {
        sort: ["id"],
      }),
      strapiFetch("/house-types", {
        sort: ["id"],
      }),
      strapiFetch("/space-styles", {
        sort: ["id"],
      }),
      strapiFetch("/space-areas", {
        sort: ["id"],
      }),
      strapiFetch("/custom-manual-subscriptions/my"),
    ]);

  return {
    user: userData,
    company: { data: companiesRes.data[0] },
    cities,
    houseTypes,
    spaceStyles,
    spaceAreas,
    subscription,
  };
};

export { AdminLayoutLoader };

export default AdminLayout;
