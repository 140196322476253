const chunkArray = <T>(arr: T[], size: number) => {
  let newArr: T[][] = [];
  for (let i = 0; i < arr.length; i += size) {
    const chunk = arr.slice(i, i + size);
    newArr.push(chunk);
  }
  return newArr;
};

export default chunkArray;
