import { Button, Form, Spin } from "antd";
import { useForm } from "antd/es/form/Form";
import {
  ActionFunctionArgs,
  Link,
  redirect,
  useNavigation,
  useRouteLoaderData,
  useSubmit,
} from "react-router-dom";
import ShowcaseFormFields from "../ShowcaseFormFields";
import { IAdminLayoutLoaderData } from "routes/AdminLayout";
import { strapiFetch } from "lib/api";

const ShowcaseCreate = () => {
  const [form] = useForm();
  const submit = useSubmit();
  const navigation = useNavigation();
  const data = useRouteLoaderData("AdminLayout") as IAdminLayoutLoaderData;
  const handleFinish = (val: Record<string, string>) => {
    const formData = new FormData();
    formData.append("body", JSON.stringify({ data: val }));
    submit(formData, { method: "post", action: "/showcase/create" });
  };

  if (navigation.state !== "idle") {
    return <Spin tip="處理中..." size="large" />;
  }

  return (
    <Form form={form} layout="vertical" onFinish={handleFinish}>
      <div className="flex justify-between items-center mb-[40px]">
        <h1 className="text-2xl">新建公司案例</h1>
        <div>
          <Link to="/showcases">
            <Button type="default">返回</Button>
          </Link>

          <Button
            type="primary"
            htmlType="submit"
            style={{ marginLeft: "15px" }}
          >
            送出
          </Button>
        </div>
      </div>
      <div className="mt-50px">
        <ShowcaseFormFields
          houseTypes={data.houseTypes}
          spaceStyles={data.spaceStyles}
          cities={data.cities}
        />
      </div>
    </Form>
  );
};

export const ShowcaseCreateAction = async ({ request }: ActionFunctionArgs) => {
  const formData = await request.formData();
  const body = formData.get("body");

  await strapiFetch(
    "/showcases",
    {},
    {
      method: "POST",
      data: JSON.parse(body as string),
    }
  );

  return redirect("/showcases");
};

export default ShowcaseCreate;
