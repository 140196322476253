import { strapiFetch } from "lib/api";

interface IUpdateShowcaseFieldsArgs {
  showcaseId: string;
  data: any;
}

const updateShowcaseFields = (args: IUpdateShowcaseFieldsArgs) => {
  const { showcaseId, data } = args;
  return strapiFetch(
    `/showcases/${showcaseId}`,
    {},
    {
      method: "PUT",
      data: { data },
    }
  );
};

export default updateShowcaseFields;
