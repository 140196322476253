import { Button, Result, ResultProps } from "antd";
import { ResultStatusType } from "antd/es/result";
import { isRouteErrorResponse, Link, useRouteError } from "react-router-dom";

interface RouteError {
  status: ResultStatusType;
  statusText: string;
}

const defaultResult: ResultProps["status"][] = [
  "404",
  "403",
  "500",
  "error",
  "info",
  "success",
  "warning",
];

const ErrorPage = () => {
  const error = useRouteError() as RouteError;

  console.error(error);

  return (
    <div className="w-screen h-screen flex justify-center items-center">
      <Result
        status={
          isRouteErrorResponse(error) && defaultResult.includes(error.status)
            ? error?.status
            : 500
        }
        title={isRouteErrorResponse(error) ? error?.status : 500}
        subTitle={
          isRouteErrorResponse(error)
            ? error?.statusText || error?.data
            : "抱歉，網站出了些問題"
        }
        extra={
          <Link to="/">
            <Button type="primary">回首頁</Button>
          </Link>
        }
      />
    </div>
  );
};

export default ErrorPage;
