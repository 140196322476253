import { IMediaCollection, IShowcaseCollection } from "types/api";
import { Button, Form, Modal, Progress, Spin, notification } from "antd";
import { useForm } from "antd/es/form/Form";
import {
  Link,
  LoaderFunctionArgs,
  redirect,
  useLoaderData,
  useNavigation,
  useParams,
  useRouteLoaderData,
  useSubmit,
} from "react-router-dom";
import ShowcaseFormFields from "../ShowcaseFormFields";
import { IAdminLayoutLoaderData } from "routes/AdminLayout";
import { strapiFetch } from "lib/api";
import ShowcaseFileUpload, {
  ShowcaseUploadFile,
  strapiImageToShowcaseUploadFile,
} from "../ShowcaseFileUpload";
import { useState } from "react";
import { useProgressUploadQueue } from "hooks";
import { assoc, equals, pick } from "ramda";
import {
  createShowcasePhoto,
  deleteShowcasePhoto,
  updateShowcasePhoto,
} from "./uploadShowcasePhoto";
import updateShowcaseFields from "./updateShowcaseFields";
import uploadShowcaseCover from "./uploadShowcaseCover";
import { CHBrandName } from "constant";

function diffFileList(
  original: ShowcaseUploadFile[],
  updated: ShowcaseUploadFile[]
) {
  const deleted: ShowcaseUploadFile[] = [];
  const updatedItems: ShowcaseUploadFile[] = [];
  const created: ShowcaseUploadFile[] = [];

  // Look for deleted and updated items
  original.forEach((item) => {
    const matchingItem = updated.find(
      (updatedItem) => updatedItem.photoId === item.photoId
    );
    if (!matchingItem) {
      deleted.push(item);
    } else if (!equals(item, matchingItem)) {
      updatedItems.push(matchingItem);
    }
  });

  // Look for created items
  updated.forEach((item) => {
    const matchingItem = original.find(
      (originalItem) => originalItem.photoId === item.photoId
    );
    if (!matchingItem) {
      created.push(item);
    }
  });

  return {
    deleted,
    updated: updatedItems,
    created,
  };
}

const ShowcaseEdit = () => {
  const params = useParams();
  const navigation = useNavigation();
  const submit = useSubmit();
  const [form] = useForm();

  const AdminLayoutData = useRouteLoaderData(
    "AdminLayout"
  ) as IAdminLayoutLoaderData;
  const { showcase } = useLoaderData() as { showcase: IShowcaseCollection };

  const photos = showcase.data.attributes.spacePhotos.data || [];
  const defaultPhotoFileList = photos
    .sort((a, b) => a.attributes.order - b.attributes.order)
    .filter((p) => !!p.attributes.image.data)
    .map((p) => {
      const image = p.attributes.image as IMediaCollection;
      return strapiImageToShowcaseUploadFile({
        ...image.data.attributes,
        order: p.attributes.order,
        photoId: p.id,
        description: p.attributes.description,
        spaceArea: p.attributes.spaceArea?.data?.id || 1,
      });
    });
  const coverDefaultFileList = showcase.data.attributes.cover.data
    ? [
        {
          uid: showcase.data.attributes.cover.data.attributes.hash,
          name: showcase.data.attributes.cover.data.attributes.name,
          status: "done",
          url: showcase.data.attributes.cover.data.attributes.url,
        } as ShowcaseUploadFile,
      ]
    : [];

  const [coverFileList, setCoverFileList] =
    useState<ShowcaseUploadFile[]>(coverDefaultFileList);
  const [photoFileList, setPhotoFileList] = useState(defaultPhotoFileList);

  const { addUploadAction, startUpload, isUploading, progress, hint, setHint } =
    useProgressUploadQueue({
      onError: () => {
        notification.error({
          message: "更新公司失敗",
          description: `更新公司失敗，請稍候在嘗試，或${CHBrandName}聯繫客服，將會儘速幫助您排查問題`,
        });
      },
      onSuccess: async () => {
        setHint("確認中");
        await new Promise(() => {
          setTimeout(() => {
            submit(new FormData(), {
              method: "put",
              action: `/showcase/${params.id}/edit`,
              encType: "multipart/form-data",
            });
          }, 1000);
        });

        return;
      },
    });

  const handleUploadPhotos = () => {
    const { deleted, created, updated } = diffFileList(
      defaultPhotoFileList,
      photoFileList.map((f, i) => ({ ...f, order: i }))
    );

    if (created.length) {
      created.forEach((file) => {
        addUploadAction(() =>
          createShowcasePhoto({
            showcaseId: showcase.data.id.toString(),
            data: pick(["order", "spaceArea", "description"], file),
            file: file.originFileObj,
          })
        );
      });
    }
    if (updated.length) {
      updated.forEach((file) => {
        addUploadAction(() =>
          updateShowcasePhoto({
            showcaseId: showcase.data.id.toString(),
            data: pick(["order", "spaceArea", "description"], file),
            photoId: file.photoId?.toString() as string,
          })
        );
      });
    }
    if (deleted.length) {
      deleted.forEach((file) => {
        addUploadAction(() =>
          deleteShowcasePhoto({
            showcaseId: showcase.data.id.toString(),
            photoId: file.photoId?.toString() as string,
          })
        );
      });
    }
  };

  const handleUploadCover = () => {
    if (equals(coverFileList[0], coverDefaultFileList[0])) return;
    if (coverFileList[0]) {
      addUploadAction(() =>
        uploadShowcaseCover({
          showcaseId: showcase.data.id.toString(),
          file: coverFileList[0].originFileObj,
        })
      );
    }
  };

  const handleFinish = async (val: any) => {
    const data = coverFileList[0] ? val : assoc("cover", null, val);
    addUploadAction(() =>
      updateShowcaseFields({
        showcaseId: showcase.data.id.toString(),
        data,
      })
    );
    handleUploadCover();
    handleUploadPhotos();

    setHint("更新資訊中");
    await startUpload();
  };

  const handleDelete = () => {
    Modal.confirm({
      title: `確定刪除案例 [${showcase.data.attributes.title}]`,
      okText: "確定",
      cancelText: "取消",
      content: <div>刪除後資料就無法復原，請確定後再按下確認按鈕</div>,
      onOk: () => {
        submit(null, {
          action: `/showcase/${params.id}/delete`,
          method: "delete",
        });
      },
    });
  };

  if (isUploading || navigation.state === "submitting") {
    return (
      <div className="h-[500px] w-full flex items-center justify-center">
        <div className="text-center">
          <Progress type="circle" percent={progress} />
          <div className="mt-[30px] font-bold text-xl animate-pulse">
            {`${hint} ...`}
          </div>
          <div className="mt-[15px] text-gray-700">
            上傳照片會花較長時間，請耐心等候
          </div>
        </div>
      </div>
    );
  }

  if (navigation.state === "loading") {
    return <Spin tip="跳轉中"></Spin>;
  }

  return (
    <div>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleFinish}
        initialValues={{
          ...showcase.data.attributes,
          spaceStyle: showcase.data.attributes.spaceStyle?.data.id,
          houseType: showcase.data.attributes.houseType?.data.id,
          location: showcase.data.attributes.location?.data.id,
        }}
      >
        <div className="flex justify-between items-center mb-[40px]">
          <h1 className="text-2xl">編輯公司案例</h1>
          <div>
            <Link to="/showcases">
              <Button type="default">返回</Button>
            </Link>
            <Button
              danger
              onClick={handleDelete}
              style={{ marginLeft: "15px" }}
            >
              刪除
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              style={{ marginLeft: "15px" }}
            >
              送出
            </Button>
          </div>
        </div>
        <div className="mt-50px">
          <ShowcaseFormFields
            houseTypes={AdminLayoutData.houseTypes}
            spaceStyles={AdminLayoutData.spaceStyles}
            cities={AdminLayoutData.cities}
          />
        </div>
        <div>
          <div className="py-[20px] mt-[15px]">上傳封面照片</div>
          <ShowcaseFileUpload
            onlyPhoto
            customOnChange={({ fileList }) => setCoverFileList(fileList)}
            maxFileSize={1}
            name="cover"
            defaultFileList={coverDefaultFileList}
          />
        </div>
        <div className="py-[20px] mt-[15px]">
          <div className="mb-[15px]">上傳照片{"(最多 20 張)"}</div>
          <ShowcaseFileUpload
            customOnChange={({ fileList }) => {
              setPhotoFileList(fileList);
            }}
            maxFileSize={20}
            name="photos"
            defaultFileList={defaultPhotoFileList}
          />
        </div>
      </Form>
    </div>
  );
};

export const showcaseEditLoader = async ({ params }: LoaderFunctionArgs) => {
  const { id } = params;
  const showcase = await strapiFetch(`/showcases/${id}`, {
    populate: {
      cover: {
        populate: "*",
      },
      spaceStyle: {
        populate: "*",
      },
      houseType: {
        populate: "*",
      },
      spacePhotos: {
        populate: "*",
      },
      location: {
        populate: "*",
      },
    },
  });
  return {
    showcase,
  };
};

export const ShowcaseEditAction = async () => {
  return redirect("/showcases");
};

export default ShowcaseEdit;
