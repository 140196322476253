import { strapiFetch } from "lib/api";
import { ActionFunctionArgs, redirect } from "react-router-dom";

export const showcaseDeleteAction = async ({ params }: ActionFunctionArgs) => {
  await strapiFetch(
    `/showcases/${params.id}`,
    {},
    {
      method: "DELETE",
    }
  );

  return redirect("/showcases");
};
