import { strapiFetch } from "lib/api";

interface ICreateShowcasePhoto {
  showcaseId: string;
  data: { order?: number };
  file: any;
}

const createShowcasePhoto = async (props: ICreateShowcasePhoto) => {
  const { showcaseId, file, data } = props;
  const formData = new FormData();
  formData.append("files.image", file);
  formData.append("data", JSON.stringify(data));

  return await strapiFetch(
    `/showcases/${showcaseId}/space-photos`,
    {},
    {
      method: "POST",
      data: formData,
    }
  );
};

export default createShowcasePhoto;
