import { PlusOutlined } from "@ant-design/icons";
import { Modal, Upload, UploadFile, UploadProps } from "antd";
import { RcFile } from "antd/es/upload";
import { useCallback, useRef, useState } from "react";
import { IMediaCollectionFields } from "types/api";
import update from "immutability-helper";
import { useDrag, useDrop } from "react-dnd";

export type CompanyPhotoUploadFile = UploadFile & {
  photoId?: number;
  order?: number;
};

export const strapiImageToCompanyUploadFile = (
  image: IMediaCollectionFields & { photoId: number; order?: number }
) => {
  return {
    uid: image.hash,
    name: image.name,
    url: image.url,
    photoId: image.photoId,
    order: image.order,
  } as CompanyPhotoUploadFile;
};

export const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => {
      reject(error);
    };
  });

interface ICustomOnChangeArgs {
  fileList: CompanyPhotoUploadFile[];
}

type IDAVUploadProps = UploadProps & {
  name: string;
  maxFileSize: number;
  customOnChange?: (args: ICustomOnChangeArgs) => void;
};

interface DragableUploadListItemProps {
  name: string;
  originNode: React.ReactElement<
    any,
    string | React.JSXElementConstructor<any>
  >;
  file: UploadFile;
  fileList: UploadFile[];
  moveRow: (dragIndex: any, hoverIndex: any) => void;
}

const type = "DragableUploadList";

const DragableUploadListItem = ({
  originNode,
  moveRow,
  file,
  fileList,
  name,
}: DragableUploadListItemProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const index = fileList.indexOf(file);
  const [{ isOver }, drop] = useDrop({
    accept: `${type}_${name}`,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
      };
    },
    drop: (item: any) => {
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    type: `${type}_${name}`,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));
  return (
    <div
      ref={ref}
      style={{
        cursor: "move",
        width: "100px",
        height: "100px",
      }}
      className={isOver ? "border-solid border-2 border-indigo-600" : ""}
    >
      {originNode}
    </div>
  );
};

const FileUpload = (props: IDAVUploadProps) => {
  const { maxFileSize, customOnChange } = props;
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState<CompanyPhotoUploadFile[]>(
    props.defaultFileList || []
  );

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file: CompanyPhotoUploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };

  const moveRow = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragRow = fileList[dragIndex];
      const newFileList = update(fileList, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRow],
        ],
      });
      setFileList(newFileList);
      if (customOnChange) {
        customOnChange({ fileList: newFileList });
      }
    },
    [fileList, customOnChange]
  );

  const uploadProps: UploadProps = {
    multiple: true,
    listType: "picture-card",
    accept: "image/png, image/jpeg, image/jpg",
    onChange: ({ fileList }) => {
      setFileList(fileList);
      if (customOnChange) {
        customOnChange({ fileList });
      }
    },
    beforeUpload: async () => {
      return false;
    },
    itemRender: (originNode, file, currFileList) => (
      <DragableUploadListItem
        name={props.name}
        originNode={originNode}
        file={file}
        fileList={currFileList}
        moveRow={moveRow}
      />
    ),
    fileList: fileList,
    onPreview: handlePreview,
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>上傳圖片</div>
    </div>
  );
  return (
    <>
      <Upload {...uploadProps} {...props} maxCount={maxFileSize}>
        {fileList.length >= maxFileSize ? null : uploadButton}
      </Upload>
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </>
  );
};

export default FileUpload;
