import { Button, Form, Progress, Spin, notification } from "antd";
import { useForm } from "antd/es/form/Form";
import CompanyFileUpload, {
  CompanyPhotoUploadFile,
  strapiImageToCompanyUploadFile,
} from "../CompanyFileUpload";
import { assoc, equals } from "ramda";
import { strapiFetch } from "lib/api";
import { useState } from "react";
import {
  useRouteLoaderData,
  Link,
  redirect,
  useSubmit,
  useNavigation,
} from "react-router-dom";
import { IAdminLayoutLoaderData } from "routes/AdminLayout";
import {
  BasicInfoFields,
  ExternalLinkFields,
  OptimizeSearchFields,
} from "routes/Onboarding";
import uploadDesignCompanyAvatar from "./uploadDesignCompanyAvatar";
import { useProgressUploadQueue } from "hooks";
import {
  createDesignCompanyPhoto,
  deleteDesignCompanyPhoto,
  updateDesignCompanyPhoto,
} from "./uploadDesignCompanyPhoto";
import updateCompanyField from "./updateCompanyFields";
import { IMediaCollection } from "types/api";
import { CHBrandName } from "constant";

function diffFileList(
  original: CompanyPhotoUploadFile[],
  updated: CompanyPhotoUploadFile[]
) {
  const deleted: CompanyPhotoUploadFile[] = [];
  const updatedItems: CompanyPhotoUploadFile[] = [];
  const created: CompanyPhotoUploadFile[] = [];

  // Look for deleted and updated items
  original.forEach((item) => {
    const matchingItem = updated.find(
      (updatedItem) => updatedItem.photoId === item.photoId
    );
    if (!matchingItem) {
      deleted.push(item);
    } else if (!equals(item, matchingItem)) {
      updatedItems.push(matchingItem);
    }
  });

  // Look for created items
  updated.forEach((item) => {
    const matchingItem = original.find(
      (originalItem) => originalItem.photoId === item.photoId
    );
    if (!matchingItem) {
      created.push(item);
    }
  });

  return {
    deleted,
    updated: updatedItems,
    created,
  };
}

const CompanyEdit = () => {
  const { company, cities, houseTypes, spaceStyles, subscription } =
    useRouteLoaderData("AdminLayout") as IAdminLayoutLoaderData;
  const submit = useSubmit();
  const navigation = useNavigation();
  const isFreeUser = subscription.data.attributes.plan === "free";
  const [form] = useForm();

  const avatarDefaultFileList = company.data.attributes.avatar.data
    ? [
        {
          uid: company.data.attributes.avatar.data.attributes.hash,
          name: company.data.attributes.avatar.data.attributes.name,
          status: "done",
          url: company.data.attributes.avatar.data.attributes.url,
        } as CompanyPhotoUploadFile,
      ]
    : [];
  const photos = [...company.data.attributes.photos.data];
  const defaultPhotoFileList = photos
    .sort((a, b) => a.attributes.order - b.attributes.order)
    .filter((p) => !!p.attributes.image.data)
    .map((p) => {
      const image = p.attributes.image as IMediaCollection;
      return strapiImageToCompanyUploadFile({
        ...image.data.attributes,
        photoId: p.id,
        order: p.attributes.order,
      });
    });
  const [avatarFileList, setAvatarFileList] = useState<
    CompanyPhotoUploadFile[]
  >(avatarDefaultFileList);
  const [photoFileList, setPhotoFileList] =
    useState<CompanyPhotoUploadFile[]>(defaultPhotoFileList);

  const { addUploadAction, startUpload, isUploading, progress, hint, setHint } =
    useProgressUploadQueue({
      onError: () => {
        notification.error({
          message: "更新公司失敗",
          description: `更新公司失敗，請稍候在嘗試，或聯繫${CHBrandName}客服，將會儘速幫助您排查問題`,
        });
      },
      onSuccess: async () => {
        setHint("確認中");
        await new Promise(() => {
          setTimeout(() => {
            submit(new FormData(), {
              method: "put",
              action: `/company/${company.data.id}/edit`,
              encType: "multipart/form-data",
            });
          }, 1000);
        });
      },
    });

  const handleAvatarUpload = () => {
    if (equals(avatarFileList[0], avatarDefaultFileList[0])) return;
    if (avatarFileList[0]) {
      addUploadAction(() =>
        uploadDesignCompanyAvatar({
          companyId: company.data.id.toString(),
          file: avatarFileList[0].originFileObj,
        })
      );
    }
  };

  const handlePhotosUpload = () => {
    const { deleted, created, updated } = diffFileList(
      defaultPhotoFileList,
      photoFileList.map((f, i) => ({ ...f, order: i }))
    );
    if (created.length) {
      created.forEach((file) => {
        addUploadAction(() =>
          createDesignCompanyPhoto({
            companyId: company.data.id.toString(),
            data: { order: file.order },
            file: file.originFileObj,
          })
        );
      });
    }
    if (updated.length) {
      updated.forEach((file) => {
        addUploadAction(() =>
          updateDesignCompanyPhoto({
            companyId: company.data.id.toString(),
            data: { order: file.order },
            photoId: file.photoId?.toString() as string,
          })
        );
      });
    }
    if (deleted.length) {
      deleted.forEach((file) => {
        addUploadAction(() =>
          deleteDesignCompanyPhoto({
            companyId: company.data.id.toString(),
            photoId: file.photoId?.toString() as string,
          })
        );
      });
    }
  };

  const handleFinish = async (val: any) => {
    const data = avatarFileList[0] ? val : assoc("avatar", null, val);
    addUploadAction(() =>
      updateCompanyField({
        companyId: company.data.id.toString(),
        data,
      })
    );

    handleAvatarUpload();
    handlePhotosUpload();

    setHint("更新公司資訊中");
    await startUpload();
  };

  if (isUploading || navigation.state === "submitting") {
    return (
      <div className="h-[500px] w-full flex items-center justify-center">
        <div className="text-center">
          <Progress type="circle" percent={progress} />
          <div className="mt-[30px] font-bold text-xl animate-pulse">
            {`${hint} ...`}
          </div>
          <div className="mt-[15px] text-gray-700">
            上傳照片會花較長時間，請耐心等候
          </div>
        </div>
      </div>
    );
  }

  if (navigation.state === "loading") {
    return <Spin tip="跳轉中"></Spin>;
  }

  return (
    <div>
      <Form
        onFinish={handleFinish}
        layout="vertical"
        form={form}
        initialValues={{
          ...company.data.attributes,
          city: company.data.attributes.city.data?.id,
          acceptableHouseTypes:
            company.data.attributes.acceptableHouseTypes.data.map((d) => d.id),
          acceptableCities: company.data.attributes.acceptableCities.data.map(
            (d) => d.id
          ),
          goodAtStyles: company.data.attributes.goodAtStyles.data.map(
            (d) => d.id
          ),
        }}
      >
        <div className="flex justify-between items-center mb-[40px]">
          <h1 className="text-2xl">編輯公司資訊</h1>
          <div>
            <Link to="/">
              <Button type="default">返回</Button>
            </Link>

            <Button
              type="primary"
              htmlType="submit"
              style={{ marginLeft: "15px" }}
            >
              送出
            </Button>
          </div>
        </div>
        <div className="flex justify-start items-start mb-[40px]">
          <div>
            <div className="mb-[30px]">上傳大頭貼</div>
            <CompanyFileUpload
              customOnChange={({ fileList }) => setAvatarFileList(fileList)}
              maxFileSize={1}
              name="avatar"
              defaultFileList={avatarDefaultFileList}
            />
          </div>
          <div className="ml-[50px]">
            <div className="mb-[30px]">
              上傳封面照片
              {isFreeUser && (
                <span className="text-red-500">
                  (開啟付費模式可以上傳多張照片)
                </span>
              )}
            </div>
            <CompanyFileUpload
              customOnChange={({ fileList }) => {
                setPhotoFileList(fileList);
              }}
              maxFileSize={isFreeUser ? 1 : 6}
              name="photos"
              defaultFileList={defaultPhotoFileList}
            />
          </div>
        </div>
        <div className="mt-50px">
          <BasicInfoFields cities={cities} />
          <ExternalLinkFields />
          <OptimizeSearchFields
            cites={cities}
            houseTypes={houseTypes}
            spaceStyles={spaceStyles}
          />
        </div>
      </Form>
    </div>
  );
};

export const CompanyEditLoader = async () => {
  const cities = await strapiFetch("/cities", {
    sort: ["id"],
  });
  const houseTypes = await strapiFetch("/house-types", {
    sort: ["id"],
  });
  const spaceStyles = await strapiFetch("/space-styles", {
    sort: ["id"],
  });

  return {
    cities,
    houseTypes,
    spaceStyles,
  };
};

export const CompanyEditAction = async () => {
  return redirect("/");
};

export default CompanyEdit;
