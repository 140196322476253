import { strapiFetch } from "lib/api";

interface IUpdateCompanyFieldArgs {
  companyId: string;
  data: any;
}

const updateCompanyField = async (args: IUpdateCompanyFieldArgs) => {
  const { companyId, data } = args;
  return strapiFetch(
    `/design-companies/${companyId}`,
    {},
    {
      data: { data },
      method: "PUT",
    }
  );
};

export default updateCompanyField;
