import { strapiFetch } from "lib/api";

interface IUploadShowcaseFile {
  showcaseId: string;
  file: any;
}

const uploadShowcaseCover = async (props: IUploadShowcaseFile) => {
  const { showcaseId, file } = props;
  const formData = new FormData();
  formData.append("files", file as any);

  await strapiFetch(
    `/custom-showcases/${showcaseId}/cover`,
    {},
    {
      method: "PUT",
      data: formData,
    }
  );
};

export default uploadShowcaseCover;
