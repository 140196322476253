export type Plan = "free" | "basic" | "plus" | "gold";

const PLAN_MAPPING: Record<Plan, string> = {
  free: "免費版",
  basic: "基礎會員",
  plus: "高階會員",
  gold: "黃金會員",
};

export default PLAN_MAPPING;
