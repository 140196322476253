import { strapiFetch } from "lib/api";

interface IUploadDesignCompanyFile {
  companyId: string;
  file: any;
}

const uploadDesignCompanyAvatar = async (props: IUploadDesignCompanyFile) => {
  const { companyId, file } = props;
  const formData = new FormData();
  formData.append("files", file as any);

  await strapiFetch(
    `/custom-design-companies/${companyId}/avatar`,
    {},
    {
      method: "PUT",
      data: formData,
    }
  );
};

export default uploadDesignCompanyAvatar;
