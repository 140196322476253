import { Button } from "antd";
import Table, { ColumnsType, TableProps } from "antd/es/table";
import { strapiFetch } from "lib/api";
import dateFormatter from "lib/dateFormatter";
import qs from "qs";
import {
  Link,
  LoaderFunctionArgs,
  useLoaderData,
  useNavigate,
} from "react-router-dom";
import {
  IShowcaseCollectionFields,
  StrapiPagination,
  StrapiParams,
} from "types/api";

type ShowcaseColumn = IShowcaseCollectionFields & { id: number };

const columns: ColumnsType<ShowcaseColumn> = [
  {
    title: "標題",
    dataIndex: "title",
    key: "title",
    render: (text) => <div>{text}</div>,
  },
  {
    title: "註解",
    dataIndex: "description",
    key: "description",
  },
  {
    title: "坪數",
    dataIndex: "size",
    key: "size",
    render: (text) => <div>{text}坪</div>,
  },
  {
    title: "預算",
    key: "budget",
    dataIndex: "budget",
    render: (text) => <div>{text}萬</div>,
  },
  {
    title: "照片數量",
    key: "photosCount",
    dataIndex: "photosCount",
    render: (_, record) => <div>{record.spacePhotos.data.length}</div>,
  },
  {
    title: "案例連結",
    key: "showcaseLink",
    dataIndex: "showcaseLink",
    render: (_, record) => (
      <Button
        type="link"
        href={`${process.env.REACT_APP_DAV_SITE_URL}/showcases/${record.id}`}
        target="_blank"
        rel="noreferrer"
      >
        前往案例
      </Button>
    ),
  },
  {
    title: "建立時間",
    key: "createdAt",
    dataIndex: "createdAt",
    render: (text) => <div>{dateFormatter(text)}</div>,
  },
  {
    title: "動作",
    key: "動作",
    render: (_, record) => (
      <Link to={`/showcase/${record.id}/edit`}>
        <Button type="default">編輯</Button>
      </Link>
    ),
  },
];

const Showcases = () => {
  const loaderData = useLoaderData() as {
    showcases: StrapiPagination<IShowcaseCollectionFields>;
    search: StrapiParams;
  };
  const navigate = useNavigate();

  const { showcases, search } = loaderData;
  const tableDataSource = showcases.data.map((d, index) => ({
    ...d.attributes,
    id: d.id,
    key: d.id,
  }));
  const handleTableChange: TableProps<ShowcaseColumn>["onChange"] = (
    ...params
  ) => {
    const [pagination] = params;

    const newSearch = {
      pagination: {
        page: pagination.current,
        pageSize: pagination.pageSize,
      },
    };
    navigate(
      `/showcases?${qs.stringify(newSearch, { encodeValuesOnly: true })}`
    );
  };

  return (
    <>
      <div className="flex justify-between items-center mb-[50px]">
        <h1 className="text-2xl">公司案例</h1>
        <div>
          <Link to={`/showcase/create`}>
            <Button type="primary">建立案例</Button>
          </Link>
        </div>
      </div>
      <Table
        columns={columns}
        dataSource={tableDataSource}
        pagination={{
          pageSize: Number(search.pagination?.pageSize) || 10,
          current: Number(search.pagination?.page) || 1,
          total: showcases.meta?.pagination.total,
        }}
        onChange={handleTableChange}
      />
    </>
  );
};

export const showcasesLoader = async ({
  params,
  request,
}: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const search = qs.parse(url.search, { ignoreQueryPrefix: true });
  const showcases = await strapiFetch("/custom-showcases/my", {
    populate: {
      spaceStyle: {
        populate: "*",
      },
      houseType: {
        populate: "*",
      },
      spacePhotos: {
        populate: "*",
      },
    },
    pagination: search.pagination,
    sort: ["updatedAt:desc"],
  });

  return {
    showcases,
    search,
  };
};

export default Showcases;
