import qs from "qs";
import localStorage from "./localStorage";
import jwtDecode from "jwt-decode";
import dayjs from "dayjs";
import axios, { AxiosRequestConfig } from "axios";

/**
 * Get full Strapi URL from path
 * @param {string} path Path of the URL
 * @returns {string} Full Strapi URL
 */
export function getStrapiURL(path: string = "") {
  return `${
    process.env.REACT_APP_STRAPI_API_URL || "http://localhost:1337"
  }${path}`;
}

/**
 * Helper to make GET requests to Strapi API endpoints
 * @param {string} path Path of the API route
 * @param {Object} urlParamsObject URL params object, will be stringified
 * @param {Object} options Options passed to fetch
 * @returns Parsed API call response
 */

interface ITokenPayload {
  id: number;
  iat: number;
  exp: number;
}

const checkToken = () => {
  let authHeader: { Authorization?: string } = {};
  const token = localStorage.getItem("token");
  if (token) {
    try {
      const decodeToken: ITokenPayload = jwtDecode(token);
      if (decodeToken.exp - 600 < dayjs().unix()) {
        // token expired
        throw new Error("Token expired.");
      }

      authHeader = {
        Authorization: `Bearer ${token}`,
      };
    } catch (e) {
      localStorage.clearAuth();
    }
  }
  return authHeader;
};

const instance = axios.create({});

export const strapiFetch = async <T>(
  path: string,
  urlParamsObject = {},
  options: AxiosRequestConfig = {}
) => {
  const authHeader = checkToken();
  const queryString = qs.stringify(urlParamsObject);
  const requestUrl = `${getStrapiURL(
    `/api${path}${queryString ? `?${queryString}` : ""}`
  )}`;

  const res = await instance<T>({
    url: requestUrl,
    ...options,
    headers: {
      ...authHeader,
      ...options.headers,
    },
  });

  if (!(res.status.toString().charAt(0) === "2")) {
    throw new Error(`${res.data}`);
  }

  return res.data;
};
