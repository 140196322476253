import { useRef, useState } from "react";

interface IUseProgressUploadQueue {
  onSuccess?: () => Promise<void>;
  onError?: () => void;
}

const useProgressUploadQueue = ({
  onSuccess,
  onError,
}: IUseProgressUploadQueue) => {
  const queue = useRef<Array<() => Promise<any>>>([]);
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [progress, setProgress] = useState(100);
  const [hint, setHint] = useState("");

  const wrapActionWithProgressCallback = (uploadAction: () => Promise<any>) => {
    return async () => {
      await uploadAction();
      setProgress((p) => {
        return parseInt((p + 100 / queue.current.length).toFixed(0));
      });
    };
  };

  const addUploadAction = (uploadAction: () => Promise<any>) => {
    if (isUploading) return;
    queue.current = [
      ...queue.current,
      wrapActionWithProgressCallback(uploadAction),
    ];
  };

  const clearUpload = () => {
    queue.current = [];
    setProgress(100);
    setIsUploading(false);
  };

  const startUpload = async () => {
    try {
      setError(null);
      setIsUploading(true);
      setProgress(0);
      const uploadPromises = queue.current.map((f) => f());
      await Promise.all(uploadPromises);
      if (onSuccess) {
        await onSuccess();
      }
    } catch (e) {
      if (onError) {
        onError();
      }
      setError(e as any);
    } finally {
      clearUpload();
    }
  };

  return {
    addUploadAction,
    hint,
    setHint,
    progress,
    queue,
    isUploading,
    error,
    startUpload,
    clearUpload,
  };
};

export default useProgressUploadQueue;
