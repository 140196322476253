import { Button, Result } from "antd";
import { CHBrandName } from "constant";
import { Link } from "react-router-dom";

const AskForPro = () => {
  return (
    <div className="w-screen h-screen flex justify-center items-center">
      <Result
        status="warning"
        title={`目前尚未開放直接註冊公司，請聯絡${CHBrandName}專員協助開通!`}
        subTitle="電話: 0911800002"
        extra={
          <Link to="/login">
            <Button type="default">重新登入</Button>
          </Link>
        }
      />
    </div>
  );
};

export default AskForPro;
