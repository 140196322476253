import { createBrowserRouter } from "react-router-dom";
import AskForPro from "routes/AskForPro";
import OnBoarding, {
  OnboardingAction,
  OnboardingLoader,
} from "routes/Onboarding";
import { AuthRedirectLoader } from "./routes/AuthRedirect";
import ErrorPage from "./routes/Error";
import Login from "./routes/Login";
import AdminLayout, { AdminLayoutLoader } from "./routes/AdminLayout";
import OnboardingLayout, {
  onboardingLayoutLoader,
} from "routes/OnboardingLayout";
import AuthLayout from "routes/AuthLayout";
import Company from "routes/Company";
import Showcases, { showcasesLoader } from "routes/Showcases";
import ConsultingLogs, { ConsultingLogsLoader } from "routes/ConsultingLogs";
import CompanyEdit, { CompanyEditAction } from "routes/Company/Edit";
import ShowcaseCreate, { ShowcaseCreateAction } from "routes/Showcases/Create";
import ShowcaseEdit, {
  ShowcaseEditAction,
  showcaseEditLoader,
} from "routes/Showcases/Edit";
import { showcaseDeleteAction } from "routes/Showcases/Delete";
import UserProfile from "routes/UserProfile";

const router = createBrowserRouter([
  {
    path: "/",
    element: <AdminLayout />,
    errorElement: <ErrorPage />,
    loader: AdminLayoutLoader,
    id: "AdminLayout",
    // Main Routes
    children: [
      {
        index: true,
        element: <Company />,
      },
      {
        path: "/user/profile",
        element: <UserProfile />,
      },
      {
        path: "/company/:id/edit",
        element: <CompanyEdit />,
        action: CompanyEditAction,
      },
      {
        path: "/showcases",
        element: <Showcases />,
        loader: showcasesLoader,
      },
      {
        path: "/showcase/create",
        element: <ShowcaseCreate />,
        action: ShowcaseCreateAction,
      },
      {
        path: "/showcase/:id/delete",
        action: showcaseDeleteAction,
      },
      {
        path: "/showcase/:id/edit",
        element: <ShowcaseEdit />,
        loader: showcaseEditLoader,
        action: ShowcaseEditAction,
      },
      {
        path: "/consulting-logs",
        element: <ConsultingLogs />,
        loader: ConsultingLogsLoader,
      },
    ],
  },
  {
    path: "/ask-for-pro",
    element: <AskForPro />,
    errorElement: <ErrorPage />,
  },
  // onboarding
  {
    path: "/",
    element: <OnboardingLayout />,
    errorElement: <ErrorPage />,
    loader: onboardingLayoutLoader,
    children: [
      {
        path: "/onboarding",
        element: <OnBoarding />,
        loader: OnboardingLoader,
        action: OnboardingAction,
        errorElement: <ErrorPage />,
      },
    ],
  },
  // Auth Routes
  {
    path: "/",
    element: <AuthLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/connect/:provider/redirect",
        loader: AuthRedirectLoader,
        errorElement: <ErrorPage />,
      },
      {
        path: "/login",
        element: <Login />,
      },
    ],
  },
]);

export default router;
