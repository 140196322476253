import { RouterProvider } from "react-router-dom";
import { SWRConfig } from "swr";
import router from "./routerConfig";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { ConfigProvider } from "antd";

function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#10B981",
          colorInfo: "#10B981",
        },
      }}
    >
      <SWRConfig
        value={{
          revalidateOnFocus: false,
          shouldRetryOnError: false,
        }}
      >
        <DndProvider backend={HTML5Backend}>
          <RouterProvider router={router} />
        </DndProvider>
      </SWRConfig>
    </ConfigProvider>
  );
}

export default App;
