const localStorage = {
  setItem(key: string, value: string) {
    if (typeof window == "undefined") return;
    window.localStorage.setItem(key, value);
  },
  getItem(key: string) {
    if (typeof window == "undefined") return;
    return window.localStorage.getItem(key);
  },
  removeItem(key: string) {
    if (typeof window == "undefined") return;
    return window.localStorage.removeItem(key);
  },
  clearAuth() {
    if (typeof window == "undefined") return;
    this.removeItem("token");
  },
};

export default localStorage;
