import { strapiFetch } from "lib/api";

interface IUpdateShowcasePhoto {
  showcaseId: string;
  photoId: string;
  data: { order?: number };
}

const updateShowcasePhoto = async (props: IUpdateShowcasePhoto) => {
  const { showcaseId, data, photoId } = props;
  return await strapiFetch(
    `/showcases/${showcaseId}/space-photos/${photoId}`,
    {},
    {
      method: "PUT",
      data: { data },
    }
  );
};

export default updateShowcasePhoto;
