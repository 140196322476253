import { strapiFetch } from "lib/api";
import { Outlet, redirect } from "react-router-dom";
import { IUserCollectionFields, StrapiPagination } from "types/api";
import { IDesignCompanyCollection } from "types/api/designCompany";

const OnboardingLayout = () => {
  return (
    <div className="w-screen h-screen flex justify-center items-start pt-[150px]">
      <Outlet />
    </div>
  );
};

export const onboardingLayoutLoader = async () => {
  let userData = null;
  try {
    userData = await strapiFetch<IUserCollectionFields>("/users/me", {
      populate: {
        role: "*",
        userProfile: {
          populate: {
            avatar: "*",
          },
        },
      },
    });
  } catch (e) {
    localStorage.clearAuth();
    throw redirect("/login");
  }
  if (!userData) throw redirect("/login");

  if (userData.role.type !== "pro") throw redirect("/ask-for-pro");

  const companiesRes = await strapiFetch<
    StrapiPagination<IDesignCompanyCollection>
  >("/design-companies", {
    filters: {
      user: userData.id,
    },
  });

  if (companiesRes.meta?.pagination.total === 1) throw redirect("/");

  if (
    companiesRes.meta?.pagination.total &&
    companiesRes.meta?.pagination.total > 1
  ) {
    // 測試帳號如果有多個 companies，不給進入
    throw new Response("衝突資料", { status: 408 });
  }

  return {
    user: userData,
  };
};

export default OnboardingLayout;
