import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  StepProps,
  Steps,
  Checkbox,
  InputNumber,
  Spin,
} from "antd";
import { useForm } from "antd/es/form/Form";
import { strapiFetch } from "lib/api";
import chunkArray from "lib/chunkArray";
import { useState } from "react";
import {
  ActionFunctionArgs,
  redirect,
  useLoaderData,
  useNavigation,
  useSubmit,
} from "react-router-dom";
import {
  ICityCollectionFields,
  IHouseTypeCollectionFields,
  ISpaceStyleCollectionFields,
  StrapiPagination,
} from "types/api";

const { Option } = Select;
const { Group: CheckboxGroup } = Checkbox;

const stepsFields = [
  ["name", "email", "phone", "taxID", "address", "city"],
  ["fb", "line", "site"],
  ["minBudget", "acceptableHouseTypes", "acceptableCities", "goodAtStyles"],
];

export const BasicInfoFields = ({
  cities,
}: {
  cities: StrapiPagination<ICityCollectionFields>;
}) => {
  return (
    <>
      <Row gutter={20}>
        <Col span={8}>
          <Form.Item
            label="公司名稱"
            name="name"
            rules={[{ required: true, message: "請輸入公司名稱" }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="客服信箱"
            name="email"
            rules={[
              { required: true, message: "請輸入客服信箱" },
              { type: "email", message: "非正確信箱格式" },
            ]}
          >
            <Input type="email" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="客服電話"
            name="phone"
            rules={[
              { required: true, message: "請輸入客服電話" },
              { pattern: /([0-9]{10})$/, message: "非正確電話格式" },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col span={8}>
          <Form.Item
            label="公司統編"
            name="taxID"
            rules={[
              { required: true, message: "請輸入公司統編" },
              { pattern: /^[0-9]{8}$/, message: "非正確統編" },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={16}>
          <Form.Item label="公司地址">
            <Input.Group compact>
              <Form.Item
                name="city"
                noStyle
                rules={[{ required: true, message: "公司城市為必填欄位" }]}
              >
                <Select placeholder="選擇城市">
                  {cities.data.map((c) => (
                    <Option key={c.attributes.name} value={c.id}>
                      {c.attributes.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="address"
                noStyle
                rules={[{ required: true, message: "公司地址為必填欄位" }]}
              >
                <Input style={{ width: "85%" }} />
              </Form.Item>
            </Input.Group>
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export const ExternalLinkFields = () => {
  return (
    <>
      <Row gutter={20}>
        <Col span={8}>
          <Form.Item
            label="公司網站連結"
            name="site"
            rules={[{ type: "url", message: "非連結格式" }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Facebook 粉專"
            name="fb"
            rules={[{ type: "url", message: "非連結格式" }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Line 好友連結"
            name="line"
            rules={[{ type: "url", message: "非連結格式" }]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export interface IOptimizeSearchFieldsProps {
  cites: StrapiPagination<ICityCollectionFields>;
  houseTypes: StrapiPagination<IHouseTypeCollectionFields>;
  spaceStyles: StrapiPagination<ISpaceStyleCollectionFields>;
}
export const OptimizeSearchFields = (props: IOptimizeSearchFieldsProps) => {
  const { cites, houseTypes, spaceStyles } = props;
  const citesChunks = chunkArray(cites.data, 8);
  const houseTypesChunks = chunkArray(houseTypes.data, 8);
  const spaceStylesChunks = chunkArray(spaceStyles.data, 8);
  return (
    <>
      <Row>
        <Form.Item
          label="接受最低預算(最低為50萬)"
          name="minBudget"
          rules={[{ required: true, message: "接受最低預算為必填" }]}
        >
          <InputNumber addonAfter="萬(新台幣)" min={50} controls={false} />
        </Form.Item>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item
            label="可接案地區"
            name="acceptableCities"
            rules={[
              {
                required: true,
                type: "array",
                message: "最少勾選一個可接案地區",
              },
            ]}
          >
            <CheckboxGroup style={{ display: "block" }}>
              {citesChunks.map((chunk, index) => (
                <Row key={index} className="w-full">
                  {chunk.map((city) => {
                    return (
                      <Col key={city.attributes.name} span={3}>
                        <Checkbox value={city.id}>
                          {city.attributes.name}
                        </Checkbox>
                      </Col>
                    );
                  })}
                </Row>
              ))}
            </CheckboxGroup>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item
            label="可接受案件類型"
            name="acceptableHouseTypes"
            rules={[
              {
                required: true,
                type: "array",
                message: "最少勾選一個可接受案件類型",
              },
            ]}
          >
            <CheckboxGroup style={{ display: "block" }}>
              {houseTypesChunks.map((chunk, index) => (
                <Row key={index} className="w-full">
                  {chunk.map((houseType) => {
                    return (
                      <Col key={houseType.attributes.name} span={3}>
                        <Checkbox value={houseType.id}>
                          {houseType.attributes.name}
                        </Checkbox>
                      </Col>
                    );
                  })}
                </Row>
              ))}
            </CheckboxGroup>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item
            label="擅長風格"
            name="goodAtStyles"
            rules={[
              {
                required: true,
                type: "array",
                message: "最少勾選一個擅長風格",
              },
            ]}
          >
            <CheckboxGroup style={{ display: "block" }}>
              {spaceStylesChunks.map((chunk, index) => (
                <Row key={index} className="w-full">
                  {chunk.map((spaceStyle) => {
                    return (
                      <Col key={spaceStyle.attributes.name} span={3}>
                        <Checkbox value={spaceStyle.id}>
                          {spaceStyle.attributes.name}
                        </Checkbox>
                      </Col>
                    );
                  })}
                </Row>
              ))}
            </CheckboxGroup>
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

const steps: StepProps[] = [
  {
    title: "公司基本資料",
    description: "請填入公司基本資料",
  },
  {
    title: "站外連結",
    description: "站外連結有助於客戶了解您的公司，也提升 SEO",
  },
  {
    title: "優化搜尋選項",
    description: "填寫公司的選項資料，增加在平台上被搜尋的機會!",
  },
];

interface IOnboardingData {
  cities: StrapiPagination<ICityCollectionFields>;
  houseTypes: StrapiPagination<IHouseTypeCollectionFields>;
  spaceStyles: StrapiPagination<ISpaceStyleCollectionFields>;
}

const Onboarding = () => {
  const navigation = useNavigation();

  const data = useLoaderData() as IOnboardingData;
  const [current, setCurrent] = useState(0);
  const [form] = useForm();
  const submit = useSubmit();
  const handleFinish = (val: Record<string, string>) => {
    const formData = new FormData();
    formData.append("body", JSON.stringify({ data: val }));
    submit(formData, { method: "post", action: "/onboarding" });
  };

  const next = async () => {
    try {
      await form.validateFields(stepsFields[current]);
      setCurrent(current + 1);
    } catch (e) {
      return;
    }
  };

  const prev = () => {
    setCurrent(current - 1);
  };
  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  if (navigation.state !== "idle") {
    return <Spin tip="建立中..." size="large" />;
  }

  return (
    <div className="min-w-[1000px]">
      <div className="text-2xl mb-[30px] text-center text-antd-primary">
        開始建立您的公司
      </div>
      <div className="my-[50px]">
        <Steps current={current} items={items} />
      </div>
      <Form onFinish={handleFinish} layout="vertical" form={form}>
        <div className="mt-[30px] position: relative">
          <div
            className={
              current === 0 ? "" : "absolute top-[100000px] left-[100000px]"
            }
          >
            <BasicInfoFields cities={data.cities} />
          </div>
          <div
            className={
              current === 1 ? "" : "absolute top-[100000px] left-[100000px]"
            }
          >
            <ExternalLinkFields />
          </div>
          <div
            className={
              current === 2 ? "" : "absolute top-[100000px] left-[100000px]"
            }
          >
            <OptimizeSearchFields
              cites={data.cities}
              houseTypes={data.houseTypes}
              spaceStyles={data.spaceStyles}
            />
          </div>

          <div className="text-right">
            {current > 0 && (
              <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
                上一步
              </Button>
            )}
            {current === steps.length - 1 && (
              <Button type="primary" htmlType="submit">
                建立
              </Button>
            )}
            {current < steps.length - 1 && (
              <Button type="primary" onClick={() => next()}>
                下一步
              </Button>
            )}
          </div>
        </div>
      </Form>
    </div>
  );
};

export const OnboardingLoader = async () => {
  const [cities, houseTypes, spaceStyles] = await Promise.all([
    strapiFetch("/cities", {
      sort: ["id"],
    }),
    strapiFetch("/house-types", {
      sort: ["id"],
    }),
    strapiFetch("/space-styles", {
      sort: ["id"],
    }),
  ]);

  return {
    cities,
    houseTypes,
    spaceStyles,
  };
};

export const OnboardingAction = async ({ request }: ActionFunctionArgs) => {
  const formData = await request.formData();
  const body = formData.get("body");

  await strapiFetch(
    "/design-companies",
    {},
    {
      data: JSON.parse(body as string),
      method: "POST",
    }
  );
  return redirect("/");
};

export default Onboarding;
