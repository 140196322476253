import dayjs from "dayjs";
import "dayjs/locale/zh-tw";
import localizedFormat from "dayjs/plugin/localizedFormat";

dayjs.locale("zh-tw");
dayjs.extend(localizedFormat);

const dateFormatter = (time: string | number | Date | dayjs.Dayjs) => {
  return dayjs(time)
    .format("LL")
    .split("")
    .map((i) => (/\d+/.test(i) ? i : ` ${i} `))
    .join("");
};

export default dateFormatter;
