import { strapiFetch } from "lib/api";

interface ICreateDesignCompanyPhoto {
  companyId: string;
  data: { order?: number };
  file: any;
}

const createDesignCompanyPhoto = async (props: ICreateDesignCompanyPhoto) => {
  const { companyId, file, data } = props;
  const formData = new FormData();
  formData.append("files.image", file);
  formData.append("data", JSON.stringify(data));

  return await strapiFetch(
    `/design-companies/${companyId}/design-company-photos`,
    {},
    {
      method: "POST",
      data: formData,
    }
  );
};

export default createDesignCompanyPhoto;
