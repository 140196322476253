import { strapiFetch } from "lib/api";

interface IDeleteDesignCompanyPhoto {
  companyId: string;
  photoId: string;
}

const deleteDesignCompanyPhoto = async (props: IDeleteDesignCompanyPhoto) => {
  const { companyId, photoId } = props;

  return await strapiFetch(
    `/design-companies/${companyId}/design-company-photos/${photoId}`,
    {},
    {
      method: "DELETE",
    }
  );
};

export default deleteDesignCompanyPhoto;
